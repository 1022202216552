<template>
  <Modal v-if="modalShown" :styles="`max-width: 512px; width: 100%;`" @close="handleCloseModal">
    <div slot="content">
      <div>
        <img class="Modal_img" src="@/assets/img/settings/delete-key-img.svg" alt="img" />
        <h2 class="Modal_warn">
          <span>Warning!</span> Are you sure you want to delete API key <strong>{{ data.name }}</strong
          >?
        </h2>
        <div class="Modal_content">
          <div class="Modal_content-row">
            {{ data.api_key }}
          </div>
          <div class="Modal_btns">
            <OutlineButton text="confirm" red fullWidth size="large" @click="handleSubmitApiKeyDelete" />
            <Button text="close" fullWidth size="large" @click="handleCloseModal" />
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/_common/Modal/Modal';
import Button from '@/components/_common/FormElements/Button/Button';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';

export default {
  props: {
    modalShown: {
      type: Boolean,
      default: false,
    },
    keyId: {
      type: [String, Number, null],
      required: true,
    },
  },
  components: {
    Modal,
    Button,
    OutlineButton,
  },
  data: () => ({}),
  computed: {
    ...mapState('user', ['userApiKeys']),
    data() {
      const key = this.userApiKeys.find(({ id }) => id === this.keyId);
      if (key) return key;
      return {};
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },
    handleSubmitApiKeyDelete() {
      this.$emit('deleteKey', this.keyId);
    },
  },
};
</script>

<style lang="scss">
.Modal_img {
  margin: 30px 0;
}
.Modal_warn {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
  span {
    color: #ec5656;
  }
  strong {
    color: #2af3f3;
  }
}

.Modal_content-row {
  margin-bottom: 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  label {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    svg {
      margin-right: 5px;
    }
  }
}

.Modal_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    flex: 0 1 49%;
  }
}
</style>
