<template>
  <Modal
    v-if="modalShown"
    :styles="`max-width: 512px; width: 100%;`"
    @close="handleCloseModal"
  >
    <div slot="content">
      <div v-if="modalStep === 'verifyKeyCreation'">
        <h2 class="Modal_title">security check</h2>
        <v-form ref="form">
          <TextFieldInput
            v-model="emailCode"
            name="emailCode"
            label="email code confirmation"
            hideDetails
            :rules="[...inputRules.required]"
          />
          <div
            class="Api_input-hint"
            v-if="userInfo"
          >
            Enter code, sent to {{ userInfo.email }}
          </div>
          <Button
            text="submit"
            fullWidth
            brightBtn
            size="large"
            @click="handleSubmitEmailCode"
          />
        </v-form>
      </div>
      <div v-if="modalStep === 'keyCreationSucess'">
        <h2 class="Modal_title">key successfully created</h2>
        <h2 class="Modal_warn"><span>Warning!</span> secret key shows 1 time</h2>
        <div class="Modal_content">
          <div class="Modal_content-row">
            <label><iconKey />API key</label>
            <div class="Modal_content-value">
              <div class="Modal_content-value-flex">
                {{ createdKeyInfo.api_key }}
                <div class="Modal_copy-btn">
                  <CopyBtn :valueToCopy="createdKeyInfo.api_key" />
                </div>
              </div>
            </div>
          </div>
          <div class="Modal_content-row">
            <label><iconKey />secret key</label>
            <div class="Modal_content-value">
              <div class="Modal_content-value-flex">
                {{ createdKeyInfo.api_secret }}
                <div class="Modal_copy-btn">
                  <CopyBtn :valueToCopy="createdKeyInfo.api_secret" />
                </div>
              </div>
            </div>
          </div>
          <Button
            text="close"
            fullWidth
            size="large"
            @click="handleCloseApiKeysModal"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/_common/Modal/Modal';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Button from '@/components/_common/FormElements/Button/Button';
import { validateForm } from '@/mixins/validateForm';
import CopyBtn from '@/components/_common/CopyBtn/CopyBtn';
import inputRules from '@/utils/inputRules';
import { iconKey } from '@/assets/icons/common';

export default {
  props: {
    modalShown: {
      type: Boolean,
      default: false,
    },
    modalStep: {
      type: String,
      // 'verifyKeyCreation', 'keyCreationSucess'
      default: 'verifyKeyCreation',
    },
    createdKeyInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    iconKey,
    CopyBtn,
    TextFieldInput,
    Modal,
    Button,
  },
  mixins: [validateForm],
  data: () => ({
    inputRules,
    emailCode: '',
  }),
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    handleCloseModal() {
      this.emailCode = ''
      this.$emit('closeModal');
    },
    handleCloseApiKeysModal() {
      this.emailCode = ''
      this.$emit('closeModal');
    },
    handleSubmitEmailCode() {
      this.validateForm().then(() => {
        this.$emit('submitKeyCreationCode', this.emailCode);
      });
    },
  },
};
</script>

<style lang="scss">
.Modal_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 21px;
}

.Api_input-hint {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin: 10px 0 20px;
  color: #fff;
}

.Modal_warn {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  span {
    color: #ec5656;
  }
}

.Modal_content-row {
  margin-bottom: 20px;
  label {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    svg {
      margin-right: 5px;
    }
  }
  .Modal_content-value {
    font-size: 14px;
    line-height: 16px;
    color: rgba(#fff, 0.44);
    text-align: left;
  }
  .Modal_content-value-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .Modal_copy-btn {
    margin-left: 10px;
  }
}
</style>
