<template>
  <div class="bg-white dark:bg-blue-800 rounded-[4px] border-[2px] border-gray-600 dark:border-[#213b57] shadow-component-wrapper-root mb-[20px]">
    <div class="apiKey_wrapper">
      <div class="flex justify-between items-center p-[20px] border-b-[1px] border-dashed border-gray-600 dark:border-blue-500/[0.21]">
        <div class="text-[18px] leading-[21px] font-medium text-gray-600 dark:text-white">{{ keyInfo.name }}</div>
        <div class="apiKey_key-btn">
          <Button
            class="btn btn-small btn-default-small min-w-[100px]"
            text="delete"
            @click="handleDeleteApiKey"
          />
        </div>
      </div>
      <div class="p-[12px] md:p-[30px]">
        <div class="md:flex md:justify-end md:items-center">
          <div class="hidden md:block bg-white dark:bg-blue-800 shadow-component-wrapper-root rounded-[4px] w-[70px] h-[70px] mr-[20px] relative md:w-full md:h-[160px] md:max-w-[160px]">
            <div class="apiKey_box-inner border-[1px] rounded-[2px] border-dashed border-gray-600 border-gray-600 dark:border-blue-500"></div>
          </div>
          <div class="mt-[15px] md:mt-0 md:w-full">
            <div class="flex-1 md:flex md:justify-between md:justify-start first-of-type:mb-[15px] md:first-of-type:mb-[45px]">
              <div class="apiKey_key-item__left-bar mb-[10px]">
                <label class="flex justify-start content-center font-bold text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white mb-[8px] text-left">
                  <SvgIcon
                    class="mr-[5px]"
                    icon="iconKey"
                  ></SvgIcon>
                  API key
                </label>
                <div class="text-[12px] leading-[14px] md:text-[14px] md:leading-[16px] text-left text-gray-600 dark:text-white/[0.44]">
                  {{ keyInfo.api_key }}
                </div>
              </div>
              <button
                class="transition rounded-[4px] inline-flex justify-center items-center text-[12px] font-bold uppercase min-w-full min-h-[30px] md:min-w-[84px] md:min-h-[40px] p-[10px] bg-blue-300 hover:bg-blue-700 text-white dark:bg-blue-500 dark:text-blue-800 dark:hover:bg-blue-400 dark:shadow-btn-hover-default"
                @click="copyApiKey"
              >
                copy
              </button>
            </div>
            <div class="flex-1 md:flex md:justify-between md:justify-start">
              <div class="apiKey_key-item__left-bar">
                <label class="flex justify-start content-center font-bold text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white mb-[8px] text-left">
                  <SvgIcon
                    class="mr-[5px]"
                    icon="iconKey"
                  ></SvgIcon>
                  secret key
                </label>
                <div class="text-[12px] leading-[14px] md:text-[14px] md:leading-[16px] text-left text-gray-600 dark:text-white/[0.44]">{{ keyInfo.truncated_api_secret }}...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
import Button from '@/components/_common/FormElements/Button/Button';
import { copyToClipboard } from '@/utils/helpers';
export default {
  props: {
    keyInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    SvgIcon,
    Button,
  },
  data() {
    return {
      copyToClipboard
    }
  },
  methods: {
    copyApiKey() {
      this.copyToClipboard(this.keyInfo.api_key);
    },
    handleDeleteApiKey() {
      this.$emit('deleteKeyRequest', this.keyInfo.id);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.apiKey_box-inner {
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  background-image: url('../../../../../assets/img/_common/lock-img.svg');
  background-position: center;
  background-size: 25px;
  background-repeat: no-repeat;

  @media only screen and (min-width: $tablet-width) {
    width: calc(100% - 44px);
    height: calc(100% - 44px);
  }
}
</style>
