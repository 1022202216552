<template>
  <div class="CopyBtn" @click="handleCopy">
    <SvgIcon
      icon="iconCopy"
    ></SvgIcon>
  </div>
</template>

<script>
import { copyToClipboard } from '@/utils/helpers';
import SvgIcon from "@/components/_common/SvgIcon";

export default {
  components: {
    SvgIcon
  },
  props: {
    valueToCopy: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    handleCopy(e) {
      e.preventDefault();
      if (this.valueToCopy) copyToClipboard(this.valueToCopy);
    },
  },
};
</script>

<style lang="scss">
.CopyBtn {
  background: rgba(#2af3f3, 0.2);
  border-radius: 2px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  position: relative;
  color: #00f3f4;
  svg {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
</style>
