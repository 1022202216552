<template>
  <div class="Api_root">
    <div class="Api_wrapper">
      <div class="Api_container">
        <h3 class="text-[18px] leading-[21px] uppercase mb-[14px] text-left text-gray-600 dark:text-white">Create key</h3>
        <div class="Api_row Api_row--halfWidth Api_row--flex">
          <v-form ref="keyValueForm">
            <TextFieldInput
              v-model="keyValue"
              name="amount_to_spend"
              placeholderValue="Enter key label"
              noRightBorder
              :rules="[() => !!keyValue || 'Please enter correct label']"
            />
          </v-form>
          <button class="Api_button" @click="handleAddKey">
            <span class="Api_button-text">Add key</span>
            <span class="Api_button-box">+</span>
          </button>
        </div>
      </div>
      <div class="Api_container" v-if="userApiKeys.length">
        <transition-group tag="div" name="fade" mode="out-in">
          <ApiKey v-for="item in userApiKeys" :key="item.id" :keyInfo="item" @deleteKeyRequest="handleDeleteApiKey" />
        </transition-group>
      </div>
      <div class="Api_container Api_container--last">
        <h3 class="text-[18px] leading-[21px] uppercase mb-[14px] text-left text-gray-600 dark:text-white">Whitelist IP</h3>
        <div class="Api_row Api_row--halfWidth Api_row--flex">
          <v-form ref="ipValueForm">
            <TextFieldInput
              v-model="ipValue"
              name="whitelist_ip"
              placeholderValue="Enter whitelist IP"
              noRightBorder
              :rules="[() => !!ipValue || 'Please enter correct IP address']"
              removeSpacesHandler
            />
          </v-form>
          <button class="Api_button" @click="handleAddWhitelistIp">
            <span class="Api_button-text">Add</span>
            <span class="Api_button-box">+</span>
          </button>
        </div>
        <div class="mt-[10px] max-w-[50%]">
          <div class="flex justify-between items-center pt-[8px] pb-[7px] border-b-[1px] border-dashed border-blue-500/[0.21]" v-for="ip in userWhiteIpList" :key="ip.id">
            <div>{{ ip.ip_addr }}</div>
            <div>
              <Button
                text="delete"
                @click="handleDeleteIp(ip.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ApiKeyCreationModal
      :modalShown="modalShown"
      :modalStep="modalStep"
      :createdKeyInfo="createdKeyInfo"
      @closeModal="handleCloseModal"
      @submitKeyCreationCode="handleSubmitEmailCode"
    />
    <ApiKeyDeletionModal
      :modalShown="deleteModalShown"
      :keyId="keyToDelete"
      @closeModal="handleCloseDeletionModal"
      @deleteKey="handleConfirmDeleteKey"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Button from '@/components/_common/FormElements/Button/Button';
import { validateForm } from '@/mixins/validateForm';
import inputRules from '@/utils/inputRules';
import ApiKeyCreationModal from './ApiKeyCreationModal';
import ApiKeyDeletionModal from './ApiKeyDeletionModal';
import ApiKey from './ApiKey';

export default {
  components: {
    TextFieldInput,
    ApiKeyCreationModal,
    ApiKeyDeletionModal,
    ApiKey,
    Button,
  },
  mixins: [validateForm],
  data: function () {
    return {
      inputRules,
      keyValue: '',
      ipValue: '',
      modalShown: false,
      deleteModalShown: false,
      createdKeyInfo: {},
      keyToDelete: '',
      //modalStep = 'verifyKeyCreation', 'keyCreationSucess',
      modalStep: 'verifyKeyCreation',
    };
  },
  computed: {
    ...mapState('user', ['userInfo', 'userApiKeys', 'userWhiteIpList']),
  },
  methods: {
    ...mapActions('settings', [
      'apiCreateApiKey',
      'apiUserVerifyApiKeyCreation',
      'apiDeleteApiKey',
      'apiCreateWhitelistIP',
      'apiDeleteWhitelistIP',
    ]),
    handleAddKey() {
      if (this.keyValue.length) {
        this.apiCreateApiKey({
          name: this.keyValue,
        }).then(() => {
          this.modalShown = true;
        });
        // this.inputKeyLabelError = false
      } else {
        // console.log('inputKeyLabelError = true')
        this.$refs.keyValueForm.validate();
        // this.inputKeyLabelError = true
        //focus input
        /*const theElement = this.$refs.inputKeyLabel.$el      
        const input = theElement.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')
        if (input) {
          setTimeout(() => {
            // input.focus()
            // input.click()
            input.blur()
          }, 0)
        }*/
        // console.log(this.$refs.inputKeyLabelWrap)
        // this.$refs.inputKeyLabelWrap.validate()
        // console.log(this.$refs.inputKeyLabel.valid)
        // console.log(this.$refs.inputKeyLabel.error)
        // this.$refs.inputKeyLabel.$el.blur()
        // this.$refs.inputKeyLabel.formHasErrors = true
      }
    },
    handleCloseModal() {
      this.modalShown = false;
      this.modalStep = 'verifyKeyCreation';
      this.createdKeyInfo = {};
      this.keyValue = '';
    },
    handleCloseDeletionModal() {
      this.keyToDelete = '';
      this.deleteModalShown = false;
    },
    handleSubmitEmailCode(code) {
      this.apiUserVerifyApiKeyCreation({ code }).then(res => {
        if (res && res.data) {
          this.createdKeyInfo = res.data;
          this.modalStep = 'keyCreationSucess';
        }
      });
    },
    handleDeleteApiKey(keyId) {
      this.keyToDelete = keyId;
      this.deleteModalShown = true;
    },
    handleConfirmDeleteKey(keyId) {
      const id_list = [`${keyId}`];
      if (id_list.length) {
        this.apiDeleteApiKey({ id_list }).then(() => {
          this.keyToDelete = '';
          this.deleteModalShown = false;
        });
      }
    },
    handleAddWhitelistIp() {
      if (this.ipValue.length) {
        this.apiCreateWhitelistIP({
          ip_addr: this.ipValue,
        }).then(() => {
          this.ipValue = '';
        });
      } else {
        this.$refs.ipValueForm.validate();
      }
    },
    handleDeleteIp(ipAddressId) {
      if (ipAddressId) {
        let id_list = [];
        id_list.push(ipAddressId);
        this.apiDeleteWhitelistIP({
          id_list,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import './Api.scss';
</style>
